<template>
    <div>
        <form @submit.prevent="validate(validateZipcode)" class="zipcode-field__wrapper">
            <div class="zipcode-field">
                <div class="zipcode-field__icon">
                    <img class="zipcode-field__icon--mobile" src="./../assets/zipcode_mobile.webp">
                    <span class="zipcode-field__icon--desktop">
                        <HelpSvg />
                    </span>
                </div>
                <div>
                    <div :class="{ 'form-field-error__container': errors.has('zipcode') }">
                        <label for="zipcode">
                            Postleitzahl der Installationsadresse
                        </label>
                        <input type="text"
                               name="zipcode"
                               pattern="[0-9]{5}"
                               maxlength="5"
                               placeholder="z.B. 10178"
                               v-validate="{ required: true, regex: /^([0-9]{5})$/ }"
                               v-model.trim="zipcode"
                               :disabled="loading"
                               id="zipcode"
                               inputmode="numeric"
                               autocomplete="off">
                        <span v-show="errors.has('zipcode')"
                              class="form-field-error__text custom-error-field">
                            {{ errors.first('zipcode') }}
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <ErrorInfoBox v-if="showErrorInfoBox" class="zipcode-field" />
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import navCommonMixin from '@/mixins/mixin.navCommon';
import HelpSvg from '../../public/svg/zipcode.svg';
import ErrorInfoBox from '@/components/ErrorInfoBox.vue';

export default {
    inject: ['$validator'],
    mixins: [navCommonMixin],
    components: {
        ErrorInfoBox,
        HelpSvg,
    },
    data() {
        return {
            step: this.$root.appConfig.steps[this.$route.params.id],
        };
    },
    computed: {
        ...mapState('ui', [
            'loading',
            'showErrorInfoBox',
        ]),
        ...mapFields('collectedData/customer', [
            'zipcode',
        ]),
    },
};
</script>
